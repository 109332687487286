// eslint-disable-next-line import/no-cycle
import store from '@/store'

export default [

  // *===============================================---*
  // *--------- ROUTES ADMINISTRATEURS  ---------------------------------------*
  // *===============================================---*
  {
    path: '/espace-structure',
    redirect: '/espace-structure/dashboard',
    component: () => import('@/layouts/espace-structure/StructureLayout.vue'),
    beforeEnter: (to, from, next) => {
      const data = JSON.parse(store.state.authStore.user)
      if (data.role === 'ROLE_STRUCTURE') next()
      else next({ name: 'misc-not-authorized' })
    },
    meta: {
      layout: 'vertical',
      redirectIfLoggedIn: true,
    },
    children: [
      // Dashboard
      {
        path: '/espace-structure/dashboard',
        name: 'espace-structure.dashboard',

        component: () => import('@/views/app/espace-structure/Home.vue'),

        meta: {
          pageTitle: 'Tableau de bord',
          redirectIfLoggedIn: true,

          breadcrumb: [
            { text: 'Tableau de bord', active: true },
          ],
        },
      },
      // Abonnement
      {
        path: '/espace-structure/abonnement',
        name: 'espace-structure.abonnement',
        component: () => import('@/views/app/espace-structure/settings/abonnement/Abonnement.vue'),
        meta: {
          pageTitle: 'Mon abonnement',
          redirectIfLoggedIn: true,

          breadcrumb: [
            {
              text: 'Tableau de bord',
              to: '/espace-structure/dashboard',

            },
            {
              text: 'Mon abonnement',
              active: true,

            },
          ],
        },
      },
      // Profile
      {
        path: '/espace-structure/profile',
        name: 'espace-structure.profile',
        component: () => import('@/views/app/espace-structure/settings/manage-profile/AccountSetting.vue'),
        meta: {
          pageTitle: 'Gestion de profil',
          redirectIfLoggedIn: true,

          breadcrumb: [
            {
              text: 'Tableau de bord',
              to: '/espace-structure/dashboard',

            },
            {
              text: 'Gestion de profil',
              active: true,

            },
          ],
        },
      },
      // Profile
      {
        path: '/espace-structure/statistiques',
        name: 'espace-structure.statistiques',
        component: () => import('@/views/app/espace-structure/settings/manage-profile/Statistiques.vue'),
        meta: {
          pageTitle: 'Statistiques des appels',
          redirectIfLoggedIn: true,

          breadcrumb: [
            {
              text: 'Tableau de bord',
              to: '/espace-structure/dashboard',

            },
            {
              text: 'Statistiques des appels',
              active: true,

            },
          ],
        },
      },
    ],
  },
]
