import mock from '@/@fake-db/mock'

/* eslint-disable import/extensions */

// JWT
import '@/@fake-db/jwt'

// Table
import '@/@fake-db/data/extensions/good-table'
import '@/@fake-db/data/extensions/auto-suggest'
import '@/@fake-db/data/card/card-statistics'
import '@/@fake-db/data/card/card-analytics'

// Apps
import '@/@fake-db/data/apps/calendar'
import '@/@fake-db/data/apps/user'
import '@/@fake-db/data/apps/email'
import '@/@fake-db/data/apps/invoice'
import '@/@fake-db/data/apps/todo'
import '@/@fake-db/data/apps/chat'
import '@/@fake-db/data/apps/eCommerce'

// dashboard
import '@/@fake-db/data/dashboard/analytics'
import '@/@fake-db/data/dashboard/ecommerce'

// pages
import '@/@fake-db/data/pages/faq-data'
import '@/@fake-db/data/pages/knowledge-base'
import '@/@fake-db/data/pages/pricing-data'
import '@/@fake-db/data/pages/account-setting'
import '@/@fake-db/data/pages/profile-data'
import '@/@fake-db/data/pages/blog'
/* eslint-enable import/extensions */

mock.onAny().passThrough() // forwards the matched request over network
