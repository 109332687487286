/* eslint-disable consistent-return */
import { ref } from '@vue/composition-api'
// eslint-disable-next-line import/no-cycle
import router from '@/router'

// eslint-disable-next-line import/no-cycle
import axiosClient from '@/helpers/axios'
import axios from 'axios'
import useAlertNotification from '@/services/notification/useAlert'

export default function useAuthUser() {
  const { errorToast, successToast } = useAlertNotification()

  const user = ref([])
  const expert = ref([])
  // const status = ref([])
  const userInformation = ref([])
  const users = ref([])
  const allNotifications = ref([])
  const unReadNotifications = ref([])
  const token = ref('')
  const status = ref(true)
  const userProcess = ref(false)
  const userSuccess = ref(false)
  const loader = ref(false)
  const errors = ref('')
  const role = ref('')
  const USER_AUTH = 'auth'
  // const API_URL = 'http://localhost:8000/api'
  const API_URL = 'https://e-cev-backend.mameribj.org/api'

  const getUserById = async id => {
    const response = await axiosClient.get(`/user/${id}`)
    userInformation.value = response.data
  }
  const getAuthUser = async () => {
    await axiosClient.get('/user').then(response => {
      status.value = response.data.status === 'ENABLED'
      // console.log(status)
      user.value = response.data
      // status.value = response
      switch (response.data.role) {
        case 'ROLE_ADMIN':
          role.value = 'Administrateur'
          break
        case 'ROLE_STRUCTURE':
          role.value = 'Structure'
          break
        default:
          break
      }
    }).catch(() => {
      user.value = undefined
    })
  }
  const getUserNotifications = async () => {
    const response = await axiosClient.get('/notifications')
    allNotifications.value = response.data
  }
  const getExpert = async () => {
    const response1 = await axiosClient.get('/user')
    const response = await axiosClient.get(`/get-expert/${response1.data.id}`)
    expert.value = response.data
  }
  const getUnreadNotifications = async () => {
    const response = await axiosClient.get('/notifications/unread')
    unReadNotifications.value = response.data
  }
  const getListeUserWithoutAdmin = async () => {
    const response = await axiosClient.get('/get-all-user-with-admin')

    users.value = response.data.data
    Object.keys(users.value).forEach(key => {
      switch (users.value[key].role) {
        case 'ROLE_STRUCTURE':
          users.value[key].role = 'Structure'
          break
        case 'ROLE_ADMIN':
          users.value[key].role = 'Administrateur'
          break

        default:
          break
      }
    })
  }
  const getListeUserStatus = async () => {
    const userstatus = 'CREATED'
    const response = await axiosClient.get(`/get-user-by-status/${userstatus}`)
    loader.value = true

    users.value = response.data.data
    Object.keys(users.value).forEach(key => {
      switch (users.value[key].role) {
        case 'ROLE_ADMIN':
          users.value[key].role = 'Administrateur'
          break
        case 'ROLE_STRUCTURE':
          users.value[key].role = 'Structure'
          break
        default:
          break
      }
    })
  }
  const updatePassword = async data => {
    try {
      errors.value = ''
      userProcess.value = true
      const response = await axiosClient.post('/update-password', data)
      if (response.data.success === true) {
        successToast('Modification', response.data.message)
        userSuccess.value = true
        userProcess.value = false
        await getAuthUser()
      }
    } catch (error) {
      userProcess.value = false
      userSuccess.value = false
      loader.value = false
      errors.value = ''
      errors.value = error.response.data.errors
      const updatePasswordErrors = error.response.data.errors
      Object.keys(updatePasswordErrors).forEach(key => {
        errorToast('Oups! Erreur', updatePasswordErrors[key][0])
      })
    }
  }
  const updateUserInformation = async data => {
    try {
      errors.value = ''
      userProcess.value = true
      const response = await axiosClient.post('/update-user-information', data)
      if (response.data.success === true) {
        successToast('Modification', response.data.message)
        userSuccess.value = true
        userProcess.value = false
        await getAuthUser()
      }
    } catch (error) {
      userProcess.value = false
      userSuccess.value = false
      loader.value = false
      errors.value = ''
      errorToast('Oups! Erreur', error.response.data.message)
    }
  }
  const changerStatusCompte = async data => {
    try {
      errors.value = ''
      userProcess.value = true
      const response = await axiosClient.post('/valider-compte', data)
      if (response.data.success === true) {
        successToast('Validation de compte', response.data.message)
        userSuccess.value = true
        userProcess.value = false
        await getListeUserStatus()
      } else if (response.data.success === false) {
        errorToast('Validation de compte', response.data.message)
        userSuccess.value = true
        userProcess.value = false
        await getListeUserStatus()
      }
    } catch (error) {
      userProcess.value = false
      userSuccess.value = false
      loader.value = false
      errors.value = ''

      if (error.response.status === 403) {
        errorToast('403 Forbidden', 'Vous n\'êtes pas autorisé(e) à effectuer cette action')
      } else if (error.response.data.message) {
        errorToast('Validation de compte', error.response.data.message)
        userSuccess.value = true
      } else {
        errors.value = error.response.data.errors
        const createUserErrors = error.response.data.errors
        Object.keys(createUserErrors).forEach(key => {
          errorToast('Oups! Erreur', createUserErrors[key][0])
        })
      }
    }
  }

  const getUserAuth = async tokenValue => {
    token.value = tokenValue
    await axios.get(`${API_URL}/user`, {
      headers: {
        Authorization: `Bearer ${token.value}`,
      },
    })
      .then(response => { user.value = response.data })
      .catch(error => { errorToast('Oups! Erreur', error.response.message) })
  }
  const getToken = async () => {
    const data = JSON.parse(localStorage.getItem(USER_AUTH))
    if (data) { token.value = data.token }
  }
  const getUserRole = () => {
    getAuthUser()
    if (user) { return role.value }
  }
  const redirectUser = async data => {
    console.log(data)
    switch (data.role) {
      case 'ROLE_ADMIN':
        router.push({ name: 'espace-admin.dashboard' })
        break
      case 'ROLE_STRUCTURE':
        router.push({ name: 'espace-structure.dashboard' })
        break
      default:
        break
    }
  }

  return {
    getAuthUser,
    getUserAuth,
    getToken,
    getUserRole,
    token,
    user,
    users,
    userProcess,
    userSuccess,
    role,
    status,
    getExpert,
    expert,
    redirectUser,
    allNotifications,
    unReadNotifications,
    getUserNotifications,
    getUnreadNotifications,
    getListeUserWithoutAdmin,
    updatePassword,
    updateUserInformation,
    getListeUserStatus,
    changerStatusCompte,
    userInformation,
    getUserById,
  }
}
