// eslint-disable-next-line import/no-cycle
import store from '@/store'

export default [

  // *===============================================---*
  // *--------- ROUTES TESTS  ---------------------------------------*
  // *===============================================---*
  {
    path: '/essayer-la-solution',
    redirect: '/espace-tests/generation',
    component: () => import('@/layouts/espace-tests/AdminLayout.vue'),
    meta: {
      layout: 'vertical',
      redirectIfLoggedIn: true,
    },
    children: [
      // Home
      {
        path: '/espace-tests/generation',
        name: 'espace-tests.generation',
        component: () => import('@/views/app/espace-tests/Home.vue'),
      },
      {
        path: '/espace-tests/generation/:type',
        name: 'espace-tests.generation.type',
        component: () => import('@/views/app/espace-tests/Generation.vue'),
      },
      {
        path: '/espace-tests/verification',
        name: 'espace-tests.verification',
        component: () => import('@/views/app/espace-tests/Verification.vue'),
      },
      // // Manage User
      // {
      //   path: '/espace-tests/manage-user',
      //   name: 'espace-tests.manage.user',
      //   component: () => import('@/views/app/espace-tests/settings/activate-account/ManageUser.vue'),
      //   meta: {
      //     pageTitle: 'Gestion des utilisateurs',
      //     redirectIfLoggedIn: true,

      //     breadcrumb: [
      //       {
      //         text: 'Tableau de bord',
      //         to: '/espace-tests/generation',

      //       },
      //       {
      //         text: 'Gestion des utilisateurs',
      //         active: true,

      //       },
      //     ],
      //   },
      // },
    ],
  },
]
